.removeNumberArrow::-webkit-inner-spin-button,
.removeNumberArrow::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  appearance: none !important;
}

.min-safe-h-screen {
  /* equivalent to 100dvh in major browsers */
  min-height: calc(
    100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0)
  );
}

/* Step Progress Bar*/
.stepProgressBar {
  --stepProgressBar-space-unit: 4px;
  --stepProgressBar-indicator-size: 16px;
  --stepProgressBar-font-size: 12px;
  --stepProgressBar-step-size: 80px;
  --stepProgressBar-step-line-size: 2px;
  --stepProgressBar-color-secondary: #d1d5db;
  --stepProgressBar-color-neutral: #fff;
  --stepProgressBar-color-neutral-400: #a3a3a3;
  --stepProgressBar-color-neutral-800: #262626;
  display: flex;
  width: 100%;
  padding: 0 24px 0 24px;
}

.stepProgressBar__step {
  display: flex;
}

.stepProgressBar__step:not(:first-child) {
  width: 100%;
}

/* --- button ---- */
.stepProgressBar__step__button {
  align-items: center;
  color: var(--stepProgressBar-color-neutral-400);
  display: flex;
  font-size: var(--stepProgressBar-font-size);
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  transition: color 0.2s ease-in;
  width: var(--stepProgressBar-step-size);
}

.stepProgressBar__step__button__indicator {
  align-items: center;
  background-color: var(--stepProgressBar-color-secondary);
  border: calc(var(--stepProgressBar-indicator-size) / 3.5) solid
    var(--stepProgressBar-color-neutral);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  height: var(--stepProgressBar-indicator-size);
  transition: background-color 0.3s ease-in;
  width: var(--stepProgressBar-indicator-size);
}

.stepProgressBar__step--current .stepProgressBar__step__button__indicator {
  background-color: var(--stepProgressBar-color-neutral);
  border: calc(var(--stepProgressBar-indicator-size) / 4) solid
    var(--color-primary);
  transition: all 0.3s ease-in;
}

.stepProgressBar__step--completed .stepProgressBar__step__button__indicator {
  border: 0;
  transition: all 0.3s ease-in;
}

.stepProgressBar__step:not(.stepProgressBar__step--current).stepProgressBar__step--navigable
  .stepProgressBar__step__button__indicator {
  background-color: var(--color-primary);
  transition: all 0.3s ease-in;
}

.stepProgressBar__step__button__indicator__icon-completed {
  fill: transparent;
}

.stepProgressBar__step--completed
  .stepProgressBar__step__button__indicator__icon-completed {
  animation: fillColorChange 0.2s ease-in 0.3s forwards;
}

.stepProgressBar__step__button__label {
  margin-top: var(--stepProgressBar-space-unit);
  width: max-content;
}

/* --- button states ---- */

@supports selector(:hover) {
  .stepProgressBar__step__button:hover {
    color: var(--color-primary);
  }
  .stepProgressBar__step:not(.stepProgressBar__step--current)
    .stepProgressBar__step__button:hover
    .stepProgressBar__step__button__indicator {
    background-color: var(--color-primary);
  }
}

.stepProgressBar__step__button:focus-visible {
  border-radius: 4px;
  outline: var(--color-primary) solid 2px;
}

@supports not selector(:focus-visible) {
  /* fallback for browsers without focus-visible support */
  .stepProgressBar__step__button:focus {
    border-radius: 4px;
    outline: var(--color-primary) solid 2px;
  }
}

/* --- line ---- */
.stepProgressBar__step__line {
  background-color: var(--stepProgressBar-color-secondary);
  height: var(--stepProgressBar-step-line-size);
  margin-top: calc(
    var(--stepProgressBar-indicator-size) / 2 -
      var(--stepProgressBar-step-line-size) / 2
  );
  margin-left: calc(var(--stepProgressBar-step-size) / 2 * -1);
  margin-right: calc(var(--stepProgressBar-step-size) / 2 * -1);
  transition: background-color 0.3s ease-in;
  width: 100%;
}

.stepProgressBar__step--navigable .stepProgressBar__step__line {
  background-color: var(--color-primary);
  transition: background-color 0.2s ease-in;
}

/* --- systempay credit card ---- */

.kr-smart-form {
  width: 100% !important;
}
.kr-embedded .kr-payment-button,
.kr-smart-form .kr-payment-button {
  background-color: var(--color-primary) !important;
  border: none !important;
  outline: none !important;
}

.kr-smart-form-list-section-name {
  display: none !important;
}

.kr-card-header {
  border-bottom-color: rgb(183, 183, 183) !important;
  border-radius: 8px !important;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;
  margin-left: -100px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* --- other ---- */

@keyframes fillColorChange {
  0% {
    fill: var(--color-primary);
  }
  100% {
    fill: var(--stepProgressBar-color-neutral);
  }
}

@supports (-webkit-touch-callout: none) {
  .min-safe-h-screen {
    /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */
    min-height: -webkit-fill-available;
  }
}
